.project-list {
    scrollbar-width: thin;
    scrollbar-color: #333 #8a8bbb;  /* Color de la barra de desplazamiento y del track */
  }
  
  /* Para navegadores basados en Webkit (Chrome, Safari) */
  .project-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .project-list::-webkit-scrollbar-track {
    background: #8a8bbb;  /* Color del track */
  }
  
  .project-list::-webkit-scrollbar-thumb {
    background-color: #333;    /* Color de la barra de desplazamiento */
    border-radius: 10px;
    border: 2px solid #8a8bbb;  /* Espacio alrededor de la barra de desplazamiento */
  }