/* buttons.css */
.button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .container {
    position: relative;
  }
  
  .logout-button {
    width: 100px;
    padding: 5px 10px;
    background-color: #8dbde2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.8em;
  }
  
  .logout-button:hover {
    background-color: #c82333;
  }