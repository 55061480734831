body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px; /* Añade un padding para evitar que el contenido se superponga al AppBar */
  }

  #myAppBar.appBar {
    background-color: white;/*#332c3c;*/
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
  }
  .logoAppBar {
    width: 150px; 
    height: auto;
    margin-right: 30px; 
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .drawer {
    width: 220px;
    flex-shrink: 0;
    background-color: white;
  }
  
  #myprojList.drawerPaper {
    background-color: #8a8bbb;/*#dbdee3; */
    width: 220px;
    top: 60px;
  }
  
  #projNew.drawerPaper {
    background-color: #dbdee3;
    padding: 10px; 
    margin-Top: 15px;
  }

  #projNew.drawerPaper2 {
    background-color: #dbdee3;
    padding: 10px;  
    margin-Top: 15px;
  }
  .mainContent {
    flex-grow: 1;
    padding: 40px 24px 24px;
  }
  
  .environmentStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .statusCircle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .statusCircle.ok {
    background-color: green;
  }
  
  .statusCircle.fail {
    background-color: red;
  }
  
  .statusCircle.warning {
    background-color: yellow;
    color: black;
  }