/* Estilos para el contenedor principal */
.main-container-step4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw; /* Asegura que el contenedor ocupe todo el ancho */
    /*overflow: hidden; /* Evita el scroll innecesario */
    padding: 0 20px; /* Asegura que haya algo de espacio a los lados en dispositivos móviles */
  }
  
  /* Estilos para el contenedor del formulario */
  .NewDep-form-container-step4 {
    width: 100%;
    max-width: 800px; /* Limitar el ancho máximo */
    margin: auto; /* Centrar horizontalmente */
    padding: 20px;
    background-color: #fff; /* Fondo blanco */
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
    /*border-radius: 8px; /* Bordes redondeados */
  }
  
  /* Estilos específicos para la cuadrícula */
  .NewDep-form-container-step4-Grid {
    width: 100%;
    margin: auto; /* Centra el formulario horizontalmente */
    padding: 20px;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    flex-direction: column; /* Alinea los elementos verticalmente */
    align-items: center;
  }
  
  /* Ajustes para títulos y subtítulos */
  .NewDep-form-container-step4 h2,
  .NewDep-form-container-step4 h4 {
    text-align: center;
  }
  
  /* Ajustes para las etiquetas */
  .NewDep-form-container-step4 label {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Ajustes para los inputs */
  .NewDep-form-container-step4 input,
  .NewDep-form-container-step4-Grid input {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Ajustes para los botones */
  .NewDep-form-container-step4 button {
    padding: 10px;
    font-size: 16px;
    width: 100px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Ajustes específicos para el botón de cancelar */
  .NewDep-form-container-step4 button[type="button"] {
    background-color: #cd4050; /* Rojo para cancelar */
    color: white;
  }
  
  /* Ajustes específicos para el botón de siguiente */
  .NewDep-form-container-step4 button[type="submit"] {
    background-color: #6a0dad; /* Verde para siguiente */
    color: white;
  }
  
  /* Ajustes responsivos */
  @media (max-width: 600px) {
    .NewDep-form-container-step4 {
      width: 90%; /* Ajuste para pantallas pequeñas */
      padding: 15px;
    }
  }