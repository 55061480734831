/* layout.css */
header {
    background: #333;
    color: white;
    padding: 10px 0;
    text-align: center;
  }
  
  footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  