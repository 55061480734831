/* Estilo del tooltip */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiciona el tooltip */
    left: 50%;
    margin-left: -60px; /* Usa la mitad del ancho del tooltip para centrarlo */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .custom-tooltip {
    padding: 10px;
    font-size: 12px;
    text-align: center;
  }
  
  /* Estilo del Dashboard */
  .dashboard {
    display: flex;
    height: 100vh;
    flex-direction: column;
  }
  
  .dashboard .navbar {
    display: flex;
    flex: 1;
    overflow: hidden;
    max-height: 70px;
  }
  
  .dashboard .content {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .dashboard .main {
    flex: 1;
    padding: 20px;
    overflow: auto;
  }
  
  /* Estilo de los elementos del Gauge */
  .gauge-container {
    text-align: center;
    margin: 20px;
  }
  
  .gauge-container h3 {
    margin-bottom: 10px;
  }
  
  .gauge-container .gauge-tooltip {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
  }
  
  .gauge-container .gauge-tooltip div {
    width: 20%;
  }
  
  /* Estilo del Paper */
  .paper {
    padding: 16px;
    display: flex;
    align-items: center;
  }
  
  .paper img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .paper .text {
    word-break: break-all;
  }
  
  /* Estilo del Drawer */
  .drawer {
    width: 240px;
    flex-shrink: 0;
  }
  
  .drawer .drawerPaper {
    width: 240px;
  }
  
  .drawer .toolbar {
    min-height: 64px;
  }
  
  .drawer .list {
    padding: 5px;
    margin-top: -50px;
  }
  
  .drawer .list .listItem {
    display: flex;
    align-items: center;
  }
  
  .drawer .list .listItemText {
    margin-left: 10px;
  }
  
  .drawer .title {
    padding: 16px;
    background-color: #f5f5f5;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }