.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 400px;
    max-width: 80%;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Ajustes específicos para el botón de cancelar */
  .modal-buttons button[type="submit"] {
    background-color: #cd4050; /* Rojo para cancelar */
    color: white;
  }
  
  /* Ajustes específicos para el botón de siguiente */
  .modal-buttons button[type="button"] {
    background-color: #6a0dad; /* Verde para siguiente */
    color: white;
  }