/* main.css */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  .container {
    width: 80%;
    margin: auto;
    overflow: hidden;
  }

  .background-image {
    position: relative;
  }
  
  .background-image::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../assets/image-login5.jpg');
    background-size: cover;
  }