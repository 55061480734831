/* forms.css */
.form-wrapper {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-top: 20px;
  }
  
  label {
    display: block;
    margin: 15px 0 5px;
  }
  
  input[type="text"], input[type="password"], input[type="email"], textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Includes padding and border in element's width/height */
  }
  
  input[type="submit"], button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #0056b3;
    cursor: pointer;
  }
  
  input[type="submit"]:hover, button:hover {
    background-color: #004494;
  }
  
  input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus {
    border-color: #0056b3;
    outline: none; /* Elimina el borde de foco predeterminado para un look más limpio */
  }
  
  /* Estilos específicos para el formulario de inicio de sesión */
  .login-form {
    background-color: white; /*#f8f9fa; /* Un fondo ligeramente diferente para distinguirlo */
  }
  
  /* Estilos específicos para el formulario de registro */
  .register-form {
    background-color: white;/* #e9ecef; /* Un fondo ligeramente diferente para distinguirlo */
  }
  
  /* Mensajes de error o confirmación */
  .message {
    color: red;
    font-size: 0.8em;
  }

  .login-form-container {
    width: 300px;  /* O el ancho que prefieras */
    margin: auto;  /* Centra el formulario horizontalmente */
    padding: 20px;
    background-color: #dbdee3; /*white;  /* Fondo del formulario */
    border-radius: 8px;  /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Sombra para mejor estética */
  }

  .register-form-container {
    width: 300px;  /* O el ancho que prefieras */
    margin: auto;  /* Centra el formulario horizontalmente */
    padding: 20px;
    background-color: #dbdee3; /*white;  /* Fondo del formulario */
    border-radius: 8px;  /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Sombra para mejor estética */
  }
  
  .project-form-container {
    width: 600px;  /* O el ancho que prefieras */
    margin: auto;  /* Centra el formulario horizontalmente */
    padding: 20px;
    background-color: white;  /* Fondo del formulario */
    border-radius: 8px;  /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Sombra para mejor estética */
  }
  
  input[type="text"], input[type="password"] {
    width: 100%;  /* Hace que los inputs ocupen todo el ancho del formulario */
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type="text"], input[type="password"], select {
    width: 100%;  /* Hace que los inputs y el select ocupen todo el ancho del formulario */
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #458eb9; /* #007bff; */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #4a6c88;/* #0056b3;*/
  }
  
  a {
    color: inherit; /* Hereda el color de texto del elemento padre */
    text-decoration: none; /* Elimina el subrayado */
    cursor: pointer; /* Cambia el cursor para indicar interactividad */
  }
  
  a:hover, a:focus {
    text-decoration: underline; /* Añade un subrayado al pasar el mouse o al enfocar para mejorar la accesibilidad */
  }
  
  .custom-h1 {
    /*color: rgb(255, 255, 255); /* Cambia a tu color preferido */
    color: rgb(255,109,122,255);
    font-size: 2.5em; /* Cambia a tu tamaño preferido */
    text-align: center; /* Alinea el texto al centro */ 
    margin-bottom: -25px;
    margin-top: -5px; 
  }

  .custom-h3 {
    color: rgb(255,109,122,255);
    /*color: rgb(255, 255, 255); /* Cambia a tu color preferido */
    font-size: 1.3em; /* Cambia a tu tamaño preferido */
    text-align: center; /* Alinea el texto al centro */ 
  }

  .logo {
    width: 250px; /* Ajusta el tamaño de la imagen */
    height: auto;
    padding: 30px;
    display: block;
    margin: 0 auto; /* Centra la imagen */
  }

  .custom-text1 {
    color: rgb(0, 0, 0);
    /*color: rgb(255, 255, 255); /* Cambia a tu color preferido */
    font-size: 1em; /* Cambia a tu tamaño preferido */
    text-align: center; /* Alinea el texto al centro */
  }