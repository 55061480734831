.login-container {
    display: flex;
    height: 100vh;
  }
  
  .background-image {
    flex: 1;
    background-size: cover;
    background-position: center;
  }
  
  .login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .login-form-container {
    width: 100%;
    max-width: 300px;
  }
  
  .login-form-container label {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .login-form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .button-group button {
    padding: 10px;
    font-size: 16px;
    width: 100px;
  }
  
  .extra-links {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
  }
  
  .custom-h1, .custom-h3 {
    text-align: center;
  }
  
  .logo {
    width: 350px;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column;
    }
  
    .background-image {
      display: none;
    }
  
    .login-form {
      padding: 10px;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .button-group button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .extra-links {
      flex-direction: column;
    }
  }